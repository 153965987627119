import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import LPAuctionBid from '@/components/ListingPage/fragments/LPAuctionBid.vue';
import { useTheme } from '@/composables/theme';
import tFormStepsFactory from '@/i18n/translations/components/formStepsFactory.json';
import { useListingStore } from '@/store/modules/listing';
import type { FactoryId, FormStepsFactory, Step } from '@/types/formStepsFactory';
import { toDateTime } from '@/utils/time';

export const useStepsLpAuctionBid = () => {
  const factoryId: FactoryId = 'lp-auction-bid';

  const { locale, t } = useI18n({ ...tFormStepsFactory, useScope: 'global' });

  const { listing } = storeToRefs(useListingStore());

  const subtitleText = computed(() =>
    listing.value?.auction?.getWorkingDaysDiff() === 0 && !listing.value.auction.dateToBeAnnounced
      ? t('subtitle.auctionBidUnavailable')
      : t('subtitle.auctionBid')
  );

  const titleText = computed(() => {
    if (listing.value?.auction?.dateToBeAnnounced) {
      return t('title.auctionBidToBeAnnounced');
    }

    const { getComponentConfig } = useTheme();
    const { CtaWidget: config } = getComponentConfig();
    return (
      toDateTime(
        listing.value?.auction?.auctionDate() || '',
        false,
        config.auctionRound.dateFormat,
        locale.value
      ) || ''
    );
  });

  const getFormStepsLpAuctionBid = (): FormStepsFactory =>
    computed<Step[]>(() => [
      {
        component: () => LPAuctionBid,
        formId: 'lp-auction-bid',
        onNextCallback: () => {
          window.open('https://altamira.thinkitweb.eu', '_blank', 'noopener noreferrer');
        },
        title: titleText.value,
        subtitle: subtitleText.value,
      },
    ]);

  return {
    factoryId,
    getFormStepsLpAuctionBid,
  };
};
