import { Screen } from 'quasar';
import { computed, toRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import type { Attribute } from '@/composables/attributesRenderer';
import { type SearchResultsItemProps, useSearchResultsItem } from '@/composables/searchResultsItem';
import { useTheme } from '@/composables/theme';
import translationsListingBadges from '@/i18n/translations/components/listingBadges.json';

export const useVarPool = (_props: SearchResultsItemProps) => {
  const props = toRef(_props);

  const searchResultsItem = useSearchResultsItem(props.value as SearchResultsItemProps);

  const { t: tGlobal } = useI18n();
  const { t: tListingBadges } = useI18n(translationsListingBadges);
  const { icons } = useTheme();

  const showRibbon = computed(
    () =>
      searchResultsItem.isAcquired.value || searchResultsItem.availabilityStatus === 'underOffer'
  );

  const badges = computed<Attribute[]>(() => [
    {
      icon: icons.new,
      key: 'new',
      value: tListingBadges('newlyListed'),
      visible: searchResultsItem.newlyListedVisible,
    },
    {
      icon: icons.hot,
      key: 'hot',
      value: tListingBadges('hot'),
      visible: searchResultsItem.listingHasLabel('hot'),
    },
    {
      icon: searchResultsItem.listingLocal.value.auction?.dateToBeAnnounced ? 'calendar' : '',
      key: 'auctionDate',
      value: searchResultsItem.listingLocal.value.auction?.dateToBeAnnounced
        ? tListingBadges('toBeAnnounced')
        : searchResultsItem.auctionDate.value,
      visible: !!searchResultsItem.auctionDate.value,
    },
  ]);
  const screenLtMdOrMap = computed(
    () => Screen.lt.md || searchResultsItem.elListingItemHasClass('listing-item--map')
  );
  const aggregates = computed<Attribute[]>(() => [
    {
      icon: icons.floor,
      key: 'floor',
      value: searchResultsItem.getFloorsText(false),
      visible: !!searchResultsItem.getFloorsText(true),
    },
    {
      icon: icons.bedrooms,
      key: 'bedrooms',
      value: `${searchResultsItem.bedrooms} ${tGlobal('bedroomShort', searchResultsItem.bedrooms)}`,
      visible: searchResultsItem.bedrooms > 0,
    },
  ]);

  watch(
    searchResultsItem.elListingItemFavoritesBtnWidth,
    v => {
      document.documentElement.style.setProperty('--favorites-btn-width', v);
    },
    { immediate: true }
  );

  return {
    ...searchResultsItem,
    aggregates,
    badges,
    screenLtMdOrMap,
    showRibbon,
  };
};
