import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import LPFormMortgage from '@/components/ListingPage/Form/LPFormMortgage.vue';
import tFormStepsFactory from '@/i18n/translations/components/formStepsFactory.json';
import type { FactoryId, FormStepsFactory, Step } from '@/types/formStepsFactory';

export const useStepsLpMortgage = () => {
  const factoryId: FactoryId = 'lp-mortgage';
  const { t } = useI18n({ ...tFormStepsFactory, useScope: 'global' });

  const getFormStepsLpMortgage = (): FormStepsFactory => {
    return computed<Step[]>(() => [
      {
        component: () => LPFormMortgage,
        formId: 'lp-mortgage',
        subtitle: t('subtitle.mortgage'),
        title: t('title.mortgage'),
      },
    ]);
  };

  return { factoryId, getFormStepsLpMortgage };
};
