<template>
  <div class="lp-skeleton-container">
    <div class="lp-skeleton-header-container bg-util-3 q-py-lg">
      <div class="row lp-skeleton-header">
        <div class="col-5 border-radius-md lp-skeleton-header-details">
          <div class="column full-height justify-center self-center">
            <div class="row q-mb-lg full-width">
              <q-skeleton
                v-for="i in 2"
                :key="i"
                type="QBadge"
                width="120px"
                height="35px"
                class="q-mr-md border-radius-xl"
              />
            </div>

            <div class="column q-gutter-sm q-mb-md">
              <q-skeleton type="text" width="100%" />
              <q-skeleton type="text" width="95%" />
              <q-skeleton type="text" width="90%" />
            </div>

            <div class="row wrap">
              <div class="row no-wrap items-center">
                <q-icon name="size" size="1.25rem" class="q-mr-sm" color="util-1" />
                <q-skeleton class="q-mr-md" type="text" width="45px" />
              </div>
              <div class="row no-wrap items-center">
                <q-icon name="floor" size="1.25rem" class="q-mr-sm" color="util-1" />
                <q-skeleton class="q-mr-md" type="text" width="45px" />
              </div>
              <div class="row no-wrap items-center">
                <q-icon name="bedrooms" size="1.25rem" class="q-mr-sm" color="util-1" />
                <q-skeleton class="q-mr-md" type="text" width="45px" />
              </div>
              <div class="row no-wrap items-center">
                <q-icon name="parking" size="1.25rem" class="q-mr-sm" color="util-1" />
                <q-skeleton class="q-mr-md" type="text" width="45px" />
              </div>
            </div>
            <div>
              <q-skeleton type="QBtn" width="100%" height="45px" class="q-mt-xl border-radius-xl" />
            </div>
          </div>
        </div>
        <div class="col lp-skeleton-header-carousel">
          <q-skeleton class="col border-radius-md" height="380px" />
        </div>
      </div>
    </div>
    <div class="row justify-center relative-position">
      <div class="col q-py-lg q-mt-sm q-mb-xl lp-skeleton-details-container">
        <div class="q-mb-xl">
          <q-skeleton type="rect" class="q-mb-lg" width="50%" height="25px" animation="fade" />
          <q-skeleton type="text" width="100%" animation="fade" />
          <q-skeleton type="text" width="80%" animation="fade" />
          <q-skeleton type="text" width="80%" animation="fade" />
        </div>
        <div class="q-mb-xl">
          <q-skeleton type="rect" class="q-mb-lg" width="50%" height="25px" animation="fade" />
          <q-skeleton type="text" width="100%" animation="fade" />
          <q-skeleton type="text" width="80%" animation="fade" />
          <q-skeleton type="text" width="80%" animation="fade" />
        </div>

        <div class="q-mb-xl">
          <q-skeleton type="rect" class="q-mb-lg" width="50%" height="25px" animation="fade" />

          <div v-for="i in 2" :key="i" class="column q-mb-md">
            <div class="row items-center q-mb-md">
              <q-skeleton type="QAvatar" class="q-mr-md" animation="fade" />
              <q-skeleton type="text" width="100px" animation="fade" />
            </div>

            <div class="row q-gutter-lg">
              <div v-for="j in 3" :key="j">
                <q-skeleton type="text" width="300px" animation="fade" />
                <q-skeleton type="text" width="200px" animation="fade" />
              </div>
            </div>
          </div>
        </div>

        <div class="q-mb-xl">
          <q-skeleton type="QBtn" width="100%" height="200px" class="border-radius-md" />
        </div>

        <q-space v-if="$q.screen.lt.md" class="q-mb-xl q-pb-lg" />
      </div>
      <!-- CTA Widget start desktop -->
      <div v-if="!$q.screen.lt.md" class="lp-skeleton-lp-form-widget">
        <div class="lp-skeleton-lp-form-widget-inner z-index-1">
          <div class="column items-center q-px-lg q-py-md">
            <q-skeleton class="q-mb-md" type="text" width="50%" height="30px" animation="fade" />
            <q-skeleton type="text" width="100%" animation="fade" />
            <q-skeleton type="text" width="80%" animation="fade" />
            <div class="row q-py-lg items-center justify-between full-width">
              <q-skeleton type="QInput" width="48%" animation="fade" />
              <q-skeleton type="QInput" width="48%" animation="fade" />
            </div>
            <div class="full-width">
              <q-skeleton type="QInput" class="q-mb-lg" width="100%" animation="fade" />
              <q-skeleton type="QInput" width="100%" animation="fade" />
            </div>
            <q-skeleton type="QBtn" width="100%" height="45px" class="q-mt-xl border-radius-xl" />
          </div>
        </div>
      </div>
      <!-- CTA Widget start mobile -->
      <div
        v-else
        class="lp-skeleton-lp-form-widget-mobile z-index-3 column justify-center items-center"
      >
        <q-skeleton type="text" width="30%" height="40px" animation="fade" />
        <div class="row no-wrap full-width">
          <q-skeleton type="QBtn" width="100%" height="56px" class="q-ma-sm border-radius-xl" />
          <q-skeleton type="QBtn" width="100%" height="56px" class="q-ma-sm border-radius-xl" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'sass:map';

.lp-skeleton-header {
  display: flex;
  gap: 5rem;
  align-items: center;
  max-width: 1376px;
  padding: 0 4.25rem;
  margin: 0 auto;

  @media (max-width: $breakpoint-sm) {
    flex-direction: column-reverse;
    gap: unset;
    max-width: 100%;
    padding: 0 1rem;
  }
}

.lp-skeleton-details-container {
  max-width: 800px;

  @media (max-width: $breakpoint-sm) {
    max-width: 100%;
    padding: 0 1rem;
  }
}

.lp-skeleton-header-container {
  @media (max-width: $breakpoint-sm) {
    padding-top: 1rem;
    margin-bottom: 1rem;
  }
}

.lp-skeleton-header-carousel {
  @media (max-width: $breakpoint-sm) {
    margin-bottom: 1rem;
  }
}

.lp-skeleton-header-carousel,
.lp-skeleton-header-details {
  @media (max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.lp-skeleton-lp-form-widget {
  min-width: 450px;
}

.lp-skeleton-lp-form-widget-inner {
  position: sticky;
  top: 36px;
  width: 100%;
  max-width: 420px;
  margin-top: 20px;
  margin-left: auto;
  background: $white;
  border: 1px solid $util-3;
  border-radius: map.get($radius-sizes, md);
  box-shadow: 0 4px 20px rgb(219 225 245 / 35%);
}

.lp-skeleton-lp-form-widget-mobile {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background: $white;
  border-radius: map.get($radius-sizes, lg) map.get($radius-sizes, lg) 0 0;
  box-shadow: 0 -4px 20px 10px rgb(219 225 245 / 35%);
}
</style>
